var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import store from 'store';
import modulesMap from 'core/modulesMap';
import MainPage from 'navigation/pages/MainPage';
import scroll from 'core/scroll';
import { bindEmitterMethod, bindMethod } from 'helpers/bind';
import { defer } from 'lodash-es';
import Form from 'components/form/Form';
import { sendProductForm } from 'core/form';
import mqStore from 'store/mqStore';
import AddToWishlist from 'components/add-to-wishlist/AddToWishlist';
import DragController from 'components/DragController/DragController';
import anime from 'animejs';
import { EASE_IN_OUT, frameToMs } from 'helpers/easings';
import { forceArray } from 'navigation/component/Component';
import ProductDetails from './partials/ProductDetails';
import ProductGallery from './partials/ProductGallery';
import ProductBar from './partials/ProductBar';
import ProductZoom from './partials/ProductZoom';
import ProductShopLook from './partials/ProductShopLook';
import ProductColorSelector from './partials/ProductColorSelector';
import ProductInfos from './partials/ProductInfos';
import ProductSizeSelector from './partials/ProductSizeSelector';
import ProductCard from './partials/ProductCard';
import ProductBreadcrumbs from './ProductBreadcrumbs';
var Product = /** @class */ (function (_super) {
    __extends(Product, _super);
    function Product() {
        var _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        Object.defineProperty(_this, "productDetailsBottom", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 0
        });
        Object.defineProperty(_this, "productGalleryBottom", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 0
        });
        Object.defineProperty(_this, "isBundle", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(_this, "oneSize", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(_this, "mobileHeaderHeight", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 56
        });
        Object.defineProperty(_this, "onFormSubmitted", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (e) {
                _this.modules.form.setSubmitted(false);
            }
        });
        Object.defineProperty(_this, "onSizeGuideBackwards", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function () {
                setTimeout(function () {
                    store.drawer.set('size-selector');
                }, frameToMs(25));
            }
        });
        Object.defineProperty(_this, "onNotifyMe", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (e) {
                e.preventDefault();
                store.drawer.set('size-selector');
            }
        });
        Object.defineProperty(_this, "onSizeChange", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (e) {
                _this.updateSizeSelectorConfirmLabel('article');
                _this.modules.details.updateSizes(e);
                if (!_this.isBundle && _this.refs.socloz)
                    _this.refs.socloz.forEach(function (link) { return link.setAttribute('data-socloz-product-attributes-clothes_size', e.label); });
            }
        });
        Object.defineProperty(_this, "updateSizeSelectorConfirmLabel", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (priorityField) {
                if (!_this.refs.addToCartSizeSelector)
                    return;
                var formData = _this.modules.form.getFormValues();
                var innerBtnTxt = _this.refs.addToCartSizeSelector.querySelector('.button__text-inner');
                var innerBtnTxtSpan = innerBtnTxt.querySelector('span');
                var notPriorityField = priorityField === 'article' ? 'article_2' : 'article';
                var capitalizedPriorityField = priorityField.charAt(0).toUpperCase() + priorityField.slice(1);
                var capitalizedNotPriorityField = notPriorityField.charAt(0).toUpperCase() + notPriorityField.slice(1);
                if (_this.isBundle) {
                    if (formData.article !== undefined && formData.article_2 !== undefined) {
                        innerBtnTxtSpan.textContent = _this.refs.addToCartSizeSelector.dataset.text;
                        innerBtnTxt.dataset.hover = _this.refs.addToCartSizeSelector.dataset.text;
                    }
                    else {
                        if (formData[priorityField] === undefined) {
                            innerBtnTxtSpan.textContent = _this.refs.addToCartSizeSelector.dataset["invalid".concat(capitalizedPriorityField)];
                            innerBtnTxt.dataset.hover = _this.refs.addToCartSizeSelector.dataset["invalid".concat(capitalizedPriorityField)];
                        }
                        if (formData[priorityField] !== undefined && formData[notPriorityField] === undefined) {
                            innerBtnTxtSpan.textContent = _this.refs.addToCartSizeSelector.dataset["invalid".concat(capitalizedNotPriorityField)];
                            innerBtnTxt.dataset.hover = _this.refs.addToCartSizeSelector.dataset["invalid".concat(capitalizedNotPriorityField)];
                        }
                    }
                }
                else {
                    if (formData.article === undefined) {
                        innerBtnTxtSpan.textContent = _this.refs.addToCartSizeSelector.dataset["invalid".concat(capitalizedPriorityField)];
                        innerBtnTxt.dataset.hover = _this.refs.addToCartSizeSelector.dataset["invalid".concat(capitalizedPriorityField)];
                    }
                    else {
                        innerBtnTxtSpan.textContent = _this.refs.addToCartSizeSelector.dataset.text;
                        innerBtnTxt.dataset.hover = _this.refs.addToCartSizeSelector.dataset.text;
                    }
                }
            }
        });
        Object.defineProperty(_this, "onAddToCart", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (e) { return __awaiter(_this, void 0, void 0, function () {
                var valid, target, cssVariables, waiter, btns;
                var _this = this;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            this.modules.form.setSubmitted(false);
                            return [4 /*yield*/, this.modules.form.quickValid()];
                        case 1:
                            valid = _a.sent();
                            if (!valid) {
                                if (store.drawer.get() !== 'size-selector')
                                    store.drawer.set('size-selector');
                                return [2 /*return*/];
                            }
                            target = e.currentTarget;
                            cssVariables = {
                                loadingProgress: 0
                            };
                            waiter = [];
                            btns = __spreadArray([], __read(this.refs.addToCart), false);
                            if (this.refs.addToCartSizeSelector)
                                btns.push(this.refs.addToCartSizeSelector);
                            btns.forEach(function (el) {
                                el.classList.add('loading');
                                var span = el.querySelector('.button__text-inner').children[0];
                                span.innerText = el.dataset.loading;
                                waiter.push(anime({
                                    targets: cssVariables,
                                    loadingProgress: [0, 1],
                                    duration: 2000,
                                    easing: EASE_IN_OUT,
                                    begin: function () {
                                        el.setAttribute('disabled', 'true');
                                    },
                                    update: function () {
                                        el.style.setProperty('--loading-progress', "".concat(cssVariables.loadingProgress));
                                    },
                                    complete: function () {
                                        el.removeAttribute('disabled');
                                    }
                                }).finished);
                            });
                            return [4 /*yield*/, Promise.all(waiter)];
                        case 2:
                            _a.sent();
                            sendProductForm({
                                form: this.modules.form,
                                submitter: target,
                                callback: function (_a) {
                                    var xhr = _a.xhr, data = _a.data;
                                    __spreadArray(__spreadArray([], __read(_this.refs.addToCart), false), [_this.refs.addToCartSizeSelector], false).forEach(function (el) {
                                        var span = el.querySelector('.button__text-inner').children[0];
                                        var hoverText = span.parentElement.dataset.hover;
                                        span.innerText = el.dataset.loading;
                                        el.classList.remove('loading');
                                        if (data.error === null)
                                            span.parentElement.dataset.hover = el.dataset.complete;
                                        else
                                            span.parentElement.dataset.hover = el.dataset.error;
                                        el.classList.add('complete');
                                        setTimeout(function () {
                                            // el.classList.add('no-transition')
                                            el.classList.remove('complete');
                                            // el.classList.remove('no-transition')
                                            span.parentElement.dataset.hover = hoverText;
                                            span.innerText = el.dataset.text;
                                        }, 1000);
                                    });
                                    setTimeout(function () {
                                        store.drawer.set(null);
                                        if (mqStore.tabletPortrait.get())
                                            _this.modules.sizeSelector.reset();
                                    }, 500);
                                }
                            });
                            return [2 /*return*/];
                    }
                });
            }); }
        });
        Object.defineProperty(_this, "onPreorder", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (e) { return __awaiter(_this, void 0, void 0, function () {
                var valid;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.modules.form.quickValid()];
                        case 1:
                            valid = _a.sent();
                            if (!valid) {
                                if (store.drawer.get() !== 'size-selector')
                                    store.drawer.set('size-selector');
                            }
                            return [2 /*return*/];
                    }
                });
            }); }
        });
        Object.defineProperty(_this, "onAddToCartSizeSelector", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (e) { return __awaiter(_this, void 0, void 0, function () {
                var valid, formData;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.modules.form.quickValid()];
                        case 1:
                            valid = _a.sent();
                            formData = this.modules.form.getFormValues();
                            if (valid) {
                                this.onAddToCart(e);
                            }
                            else {
                                if (this.isBundle) {
                                    if (this.modules.sizeSelector.tab === 0) {
                                        if (formData.article !== undefined)
                                            this.modules.sizeSelector.onTabClick(1, false);
                                    }
                                    else {
                                        if (formData.article_2 !== undefined)
                                            this.modules.sizeSelector.onTabClick(0, false);
                                    }
                                }
                            }
                            return [2 /*return*/];
                    }
                });
            }); }
        });
        Object.defineProperty(_this, "onToggleColorSelector", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function () {
                store.drawer.set('color-selector');
            }
        });
        Object.defineProperty(_this, "onToggleSizeSelector", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (e) {
                if (e.currentTarget.dataset.oneSize)
                    return;
                var target = e.currentTarget;
                store.drawer.set('size-selector');
                if (_this.isBundle) {
                    var tab = parseInt(target.dataset.tab);
                    _this.modules.sizeSelector.onTabClick(tab, true);
                }
            }
        });
        Object.defineProperty(_this, "onTabChange", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (index) {
                _this.updateSizeSelectorConfirmLabel(index === 0 ? 'article' : 'article_2');
            }
        });
        Object.defineProperty(_this, "onScroll", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function () {
                if (_this._hiding)
                    return;
                var isTabletPortrait = mqStore.tabletPortrait.get();
                var belowBottom = false;
                var galleryAbove = scroll.scrollTop() > _this.productGalleryBottom;
                if (isTabletPortrait)
                    belowBottom = _this.productDetailsBottom - 100 > 0 && (scroll.scrollTop() + window.innerHeight - 180) > _this.productDetailsBottom;
                else
                    belowBottom = _this.productDetailsBottom - 100 > 0 && scroll.scrollTop() > _this.productDetailsBottom;
                if (!isTabletPortrait)
                    _this.modules.bar.visible = belowBottom;
                else
                    _this.modules.bar.el.classList.toggle('in-flow', belowBottom);
                if (_this.modules.bar.visible && (belowBottom || galleryAbove))
                    store.headerVariants.set(['solid', 'light']);
                else
                    store.headerVariants.set(['transparent', 'light']);
            }
        });
        return _this;
    }
    Object.defineProperty(Product.prototype, "getModulesMap", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var map = Object.assign({}, modulesMap);
            delete map.form;
            return __assign(__assign({}, map), { form: ['.form.product__top', Form], bar: ['.product-bar', ProductBar], zoom: ['.product-zoom', ProductZoom], details: ['.product-details', ProductDetails], gallery: ['.product-gallery', ProductGallery], shopLook: ['.shop-look', ProductShopLook], infos: ['.product-infos', ProductInfos], colorSelector: ['.color-selector', ProductColorSelector], sizeSelector: ['.size-selector', ProductSizeSelector], addToWishlistLarge: ['.add-to-wishlist__large', AddToWishlist], xlinking: ['.xlinking__list', DragController], breadcrumbs: ['.product__breadcrumbs', ProductBreadcrumbs], card: ['.product-card__related', ProductCard] });
        }
    });
    Object.defineProperty(Product.prototype, "askPreload", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (previousPage) {
            return _super.prototype.askPreload.call(this, previousPage);
        }
    });
    Object.defineProperty(Product.prototype, "preload", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (previousPage) {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.preloadImages()];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    Object.defineProperty(Product.prototype, "getModuleParams", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (el, componentConstructor) {
            return __assign(__assign({}, _super.prototype.getModuleParams.call(this, el, componentConstructor)), { gradient: true });
        }
    });
    Object.defineProperty(Product.prototype, "bindModules", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            _super.prototype.bindModules.call(this);
        }
    });
    Object.defineProperty(Product.prototype, "initialized", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var _this = this;
            _super.prototype.initialized.call(this);
            defer(function () {
                _this.productDetailsBottom = _this.modules.gallery.el.getBoundingClientRect().bottom - _this.refs.productDetailsInner.getBoundingClientRect().bottom;
                _this.isBundle = _this.modules.form.el.classList.contains('is_bundle_product');
                _this.oneSize = !!_this.refs.productDetailsInner.dataset.oneSize;
                _this.modules.form.quickValid();
                if (_this.modules.sizeSelector)
                    _this.updateSizeSelectorConfirmLabel('article');
            });
        }
    });
    Object.defineProperty(Product.prototype, "show", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (previousPage) {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, _super.prototype.show.call(this, previousPage)];
                        case 1:
                            _a.sent();
                            window.dispatchEvent(new CustomEvent('product_view'));
                            return [2 /*return*/, Promise.resolve()];
                    }
                });
            });
        }
    });
    Object.defineProperty(Product.prototype, "pageName", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return 'Product';
        }
    });
    Object.defineProperty(Product.prototype, "bindEvents", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (add) {
            var _this = this;
            var _a, _b, _c;
            var emitterMethod = bindEmitterMethod(add);
            var method = bindMethod(add);
            (_b = this === null || this === void 0 ? void 0 : (_a = this.el)[method]) === null || _b === void 0 ? void 0 : _b.call(_a, 'show-size', this.onSizeGuideBackwards);
            if (this.refs.toggleColorSelector)
                this.refs.toggleColorSelector[method]('click', this.onToggleColorSelector);
            scroll[emitterMethod](this.onScroll);
            if (this.refs.toggleColorSelector)
                this.refs.toggleColorSelector[method]('click', this.onToggleColorSelector);
            if (this.refs.toggleSizeSelector)
                this.refs.toggleSizeSelector.forEach(function (toggle) { return toggle[method]('click', _this.onToggleSizeSelector); });
            this.modules.form[emitterMethod]('submitted', this.onFormSubmitted);
            (_c = this.refs.addToCart) === null || _c === void 0 ? void 0 : _c.forEach(function (el) { return el[method]('click', _this.onAddToCart); });
            if (this.refs.addToCartSizeSelector)
                this.refs.addToCartSizeSelector[method]('click', this.onAddToCartSizeSelector);
            if (this.modules.sizeSelector) {
                this.modules.sizeSelector[emitterMethod]('change', this.onSizeChange);
                this.modules.sizeSelector[emitterMethod]('tab:change', this.onTabChange);
            }
            if (this.modules.bar)
                this.modules.bar[emitterMethod]('add-to-cart', this.onAddToCart);
            if (this.refs.notifyMe)
                forceArray(this.refs.notifyMe).forEach(function (notifyMe) { return notifyMe[method]('click', _this.onNotifyMe); });
            if (this.refs.preorder)
                forceArray(this.refs.preorder).forEach(function (preorder) { return preorder[method]('click', _this.onPreorder); });
            if (this.modules.infos)
                this.modules.infos[emitterMethod]('open:info', function () { return _this.resize(); });
        }
    });
    Object.defineProperty(Product.prototype, "hide", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this._hiding = true;
            return _super.prototype.hide.call(this);
        }
    });
    Object.defineProperty(Product.prototype, "setToggleSizeSelectorPadding", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            if (!this.refs.toggleColorSelector)
                this.refs.toggleSizeSelector[0].style.paddingLeft = 0 + 'px';
        }
    });
    Object.defineProperty(Product.prototype, "resize", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            _super.prototype.resize.call(this);
            this.setToggleSizeSelectorPadding();
            this.productGalleryBottom = this.modules.gallery.el.offsetHeight - this.mobileHeaderHeight;
            if (mqStore.tabletPortrait.get())
                this.productDetailsBottom = this.productGalleryBottom + this.modules.details.el.offsetHeight + this.modules.infos.el.offsetHeight;
            else
                this.productDetailsBottom = (this.modules.gallery.el.offsetTop + this.modules.gallery.el.offsetHeight) - (this.refs.productDetailsInner.offsetTop + this.refs.productDetailsInner.offsetHeight);
        }
    });
    Object.defineProperty(Product.prototype, "flush", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            _super.prototype.flush.call(this);
        }
    });
    return Product;
}(MainPage));
export default Product;
