var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { defer, each, zipObject } from 'lodash-es';
import * as yup from 'yup';
import Component from 'navigation/component/Component';
import scroll from 'core/scroll';
import resize from 'helpers/resize';
import sizeStore from 'store/sizeStore';
import validators from './components/validators';
var getFormParameters = function (event) {
    var formData = new FormData(event.currentTarget);
    if (event.submitter && event.submitter.name)
        formData.append(event.submitter.name, event.submitter.value);
    var urlParameters = '?' + __spreadArray([], __read(formData.entries()), false).map(function (x) { return "".concat(encodeURIComponent(x[0]), "=").concat(encodeURIComponent(x[1])); })
        .join('&');
    return {
        formData: formData,
        urlParameters: urlParameters
    };
};
var Form = /** @class */ (function (_super) {
    __extends(Form, _super);
    function Form(el, _a) {
        var parent = _a.parent, pageManager = _a.pageManager;
        var _this = _super.call(this, el) || this;
        Object.defineProperty(_this, "pageManager", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(_this, "preventDefault", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(_this, "submitted", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(_this, "method", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 'POST'
        });
        Object.defineProperty(_this, "action", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: '/'
        });
        Object.defineProperty(_this, "inputs", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: []
        });
        Object.defineProperty(_this, "checkboxes", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: []
        });
        Object.defineProperty(_this, "validations", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {}
        });
        Object.defineProperty(_this, "disabled", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(_this, "schema", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: yup.object().shape({})
        });
        Object.defineProperty(_this, "inputsByPath", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {}
        });
        Object.defineProperty(_this, "submitCallback", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(_this, "onReset", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function () {
                _this.setSubmitted.bind(false);
                _this.inputs.forEach(function (input) {
                    input.removeAttribute('value');
                });
            }
        });
        Object.defineProperty(_this, "onInput", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (event) {
                _this.emit('input', event);
                _this.validateField(event.currentTarget.name, false);
                _this.quickValid();
            }
        });
        Object.defineProperty(_this, "onBlur", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (event) {
                if (event.currentTarget.value !== '')
                    _this.validateField(event.currentTarget.name, true);
            }
        });
        Object.defineProperty(_this, "clear", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function () {
                var form = _this.el;
                form.reset();
            }
        });
        Object.defineProperty(_this, "onFormSubmit", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (event) {
                if (_this.submitted)
                    return event.preventDefault();
                if (event.submitter && event.submitter.getAttribute('data-valid') === 'false')
                    return _this.submit(event);
                if (event.target.hasAttribute('data-direct'))
                    return true;
                var values = _this.getFormValues();
                try {
                    _this.schema.validateSync(values, { abortEarly: false, context: values });
                    _this.setSubmitted(true);
                    if (_this.preventDefault) {
                        event.preventDefault();
                        _this.submit(event);
                    }
                }
                catch (e) {
                    event.preventDefault();
                    if (e.errors || e.inner)
                        return _this.updateErrors(_this.formatError(e));
                    throw e;
                }
                // .then(() => {
                // })
                // .catch(e => {
                //   if (e.errors || e.inner) return this.updateErrors(this.formatError(e))
                //   throw e
                // })
            }
        });
        // this.pageManager = parent.pageManager
        _this.preventDefault = true;
        _this.submitted = false;
        defer(function () {
            var _a, _b;
            _this.pageManager =
                (parent === null || parent === void 0 ? void 0 : parent.pageManager) ||
                    ((_b = (_a = parent === null || parent === void 0 ? void 0 : parent.options) === null || _a === void 0 ? void 0 : _a.parent) === null || _b === void 0 ? void 0 : _b.pageManager) || pageManager;
            if (!_this.pageManager) {
                if (parent.modules.content)
                    _this.pageManager = parent.modules.content;
                else
                    throw new Error('Form must be a child of a Page');
            }
        });
        _this.bindRefs();
        _this.parseForm();
        _this.quickValid();
        return _this;
    }
    Object.defineProperty(Form.prototype, "getModulesMap", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return {};
        }
    });
    Object.defineProperty(Form.prototype, "parseForm", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var _this = this;
            this.method = (this.el.getAttribute('method') || 'POST').toUpperCase();
            this.action = this.el.getAttribute('action') || window.location.pathname;
            this.inputs = __spreadArray([], __read(this.el.querySelectorAll('[name][data-validation], [name][data-required]')), false);
            this.checkboxes = __spreadArray([], __read(this.el.querySelectorAll('[type="checkbox"][data-validation]')), false);
            this.inputsByPath = {};
            this.validations = this.inputs.reduce(function (memo, input) {
                var required = input.hasAttribute('data-required');
                var validKey = input.getAttribute('data-validation');
                var name = _this.formatName(input.name);
                var validator = validators[validKey];
                if (required)
                    validator = validators.required(validator);
                if (validator)
                    memo[name] = validator;
                if (!_this.inputsByPath[name])
                    _this.inputsByPath[name] = [];
                _this.inputsByPath[name].push(input);
                return memo;
            }, {});
            this.updateSchema(this.validations);
        }
    });
    Object.defineProperty(Form.prototype, "formatName", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (name) {
            return name.replace('[', '___').replace(']', '___');
        }
    });
    Object.defineProperty(Form.prototype, "updateSchema", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (validations) {
            this.schema = yup.object().shape(validations);
        }
    });
    Object.defineProperty(Form.prototype, "bindEvents", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (add) {
            var _this = this;
            if (add === void 0) { add = true; }
            var method = add ? 'addEventListener' : 'removeEventListener';
            this.el[method]('submit', this.onFormSubmit);
            this.el[method]('reset', this.onReset);
            this.inputs.forEach(function (input) {
                input[method]('input', _this.onInput);
                input[method]('blur', _this.onBlur);
            });
        }
    });
    Object.defineProperty(Form.prototype, "validateField", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (path, addError) {
            var _this = this;
            if (addError === void 0) { addError = false; }
            path = this.formatName(path);
            var inputs = this.inputsByPath[path];
            // @ts-ignore
            if (!~this.schema._nodes.indexOf(path))
                return;
            var values = this.getFormValues();
            this.schema.validateAt(path, values, { context: values })
                .then(function () {
                inputs.forEach(function (i) {
                    // @ts-ignore
                    i.parentNode.parentNode.style.setProperty('--form-error', '');
                    // @ts-ignore
                    i.parentNode.parentNode.style.setProperty('--error-height', '14px');
                    i.parentNode.parentElement.classList.remove('error');
                });
            })
                .catch(function (_a) {
                var errors = _a.errors;
                var error = errors[0] || '';
                if (addError) {
                    inputs.forEach(function (i) {
                        if (window.dataLayer) {
                            // @ts-ignore
                            var form = i.closest('form[data-name]');
                            window.dataLayer.push({
                                event: 'error_field',
                                field_error_type: error.trim() || 'empty',
                                field_name: i.getAttribute('name'),
                                //
                                form_name: form ? form.getAttribute('data-name') : 'none'
                            });
                        }
                        // @ts-ignore
                        i.parentNode.parentNode.style.setProperty('--form-error', _this.getError(error));
                        // @ts-ignore
                        i.parentNode.parentNode.style.setProperty('--error-height', '14px');
                        i.parentNode.parentElement.classList.add('error');
                    });
                }
            });
        }
    });
    Object.defineProperty(Form.prototype, "quickValid", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var _this = this;
            var values = this.getFormValues();
            return this.schema.validate(values, { abortEarly: false, context: values })
                .then(function (e) {
                _this.emit('validate');
                _this.el.classList.add('valid');
                _this.el.classList.remove('not-valid');
                return true;
            })
                .catch(function (e) {
                _this.emit('validate');
                _this.el.classList.add('not-valid');
                _this.el.classList.remove('valid');
                return false;
            });
        }
    });
    Object.defineProperty(Form.prototype, "getFormValues", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var _this = this;
            var formData = new FormData(this.el);
            var keys = __spreadArray([], __read(formData.keys()), false);
            return zipObject(keys.map(function (k) { return _this.formatName(k); }), keys.map(function (k) { return formData.get(k); }));
        }
    });
    Object.defineProperty(Form.prototype, "setSubmitted", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (submitted) {
            if (submitted === void 0) { submitted = true; }
            this.submitted = submitted;
            this.el.classList.toggle('submitted', submitted);
        }
    });
    Object.defineProperty(Form.prototype, "formatError", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (error) {
            var errors = error.inner && error.inner.length ? error.inner : [Object.assign({}, error)];
            return errors.reduce(function (memo, error) {
                if (!memo[error.path] && error.errors)
                    memo[error.path] = error.errors[0];
                return memo;
            }, {});
        }
    });
    Object.defineProperty(Form.prototype, "updateErrors", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (errors) {
            var _this = this;
            var scrolled = false;
            each(this.inputsByPath, function (inputs, path) {
                var error = errors[path];
                inputs.forEach(function (i, k) {
                    if (!scrolled && !!error) {
                        _this.scrollTo(i);
                        scrolled = true;
                    }
                    // @ts-ignore
                    i.parentNode.parentElement.style.setProperty('--form-error', _this.getError(error));
                    i.parentNode.parentElement.classList.toggle('error', !!error);
                });
            });
        }
    });
    Object.defineProperty(Form.prototype, "scrollTo", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (el) {
            if (scroll.locked())
                return;
            var inc = sizeStore.notificationHeight.get() + sizeStore.headerHeight.get();
            var top = el.getBoundingClientRect().top + scroll.scrollTop() - inc - 100;
            var s = Math.min(Math.max(0, top), document.scrollingElement.scrollHeight - resize.height());
            scroll.scrollTo(s);
        }
    });
    Object.defineProperty(Form.prototype, "submit", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (event) {
            var _this = this;
            if (this.disabled)
                return;
            var p = getFormParameters(event || { currentTarget: this.el });
            var action = this.action;
            if (event && event.submitter && event.submitter.hasAttribute('data-action'))
                action = event.submitter.getAttribute('data-action');
            if (this.method === 'GET')
                action += p.urlParameters;
            // this.el.classList.add('submitting')
            return Promise.resolve()
                .then(function () {
                if (_this.submitCallback)
                    return _this.submitCallback(p.formData, p.urlParameters);
                // else this.el.classList.remove('submitting')
            })
                .then(function (prevent) {
                // this.el.classList.remove('submitting')
                if (_this.pageManager.state.loading || prevent === false)
                    return;
                _this.pageManager.once('loaded', function () {
                    _this.setSubmitted(false);
                });
                _this.pageManager.virtual(action, {
                    body: p.formData, method: _this.method
                });
                _this.emit('submitted');
            });
        }
    });
    Object.defineProperty(Form.prototype, "hide", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.disabled = true;
        }
    });
    Object.defineProperty(Form.prototype, "setSubmitCallback", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (cb) {
            this.submitCallback = cb;
        }
    });
    Object.defineProperty(Form.prototype, "getError", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (errorId) {
            if (!errorId)
                return '';
            else
                return JSON.stringify(errorId);
        }
    });
    Object.defineProperty(Form.prototype, "flush", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.inputs = null;
            this.schema = null;
            this.checkboxes = null;
        }
    });
    return Form;
}(Component));
export { getFormParameters };
export default Form;
