var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import PanelPage from 'navigation/pages/PanelPage';
import RowProductCard from 'sections/product/partials/RowProductCard';
import Form from 'components/form/Form';
import { bindMethod } from 'helpers/bind';
import scroll from 'core/scroll';
import anime from 'animejs';
import mqStore from 'store/mqStore';
import { frameToMs } from 'helpers/easings';
import { defer } from 'lodash-es';
import store from 'store';
import CheckoutFooter from './CheckoutFooter';
var CheckoutSummary = /** @class */ (function (_super) {
    __extends(CheckoutSummary, _super);
    function CheckoutSummary() {
        var _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        Object.defineProperty(_this, "toggleSummary", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function () {
                _this.el.parentElement.classList.toggle('opened');
                var duration = 700;
                if (_this.el.parentElement.classList.contains('opened')) {
                    scroll.lock();
                    _this.refs.summaryWrapper.style.display = 'block';
                    _this.setProductsWrapperHeight();
                    anime({
                        targets: _this.refs.summaryWrapper,
                        translateY: ['100%', 0],
                        easing: 'cubicBezier(0.645, 0.045, 0.355, 1)',
                        duration: duration
                    });
                }
                else {
                    anime({
                        targets: _this.refs.summaryWrapper,
                        translateY: [0, '100%'],
                        easing: 'cubicBezier(0.645, 0.045, 0.355, 1)',
                        duration: duration
                    }).finished.then(function () {
                        _this.refs.summaryWrapper.style.display = 'none';
                        scroll.unlock();
                    });
                }
            }
        });
        Object.defineProperty(_this, "clearCoupon", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function () {
                _this.modules.couponForm.clear();
                _this.modules.couponForm.submit().then(function () {
                    _this.modules.couponForm.setSubmitted(false);
                });
            }
        });
        return _this;
    }
    Object.defineProperty(CheckoutSummary.prototype, "bindEvents", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (add) {
            if (add === void 0) { add = true; }
            _super.prototype.bindEvents.apply(this, __spreadArray([], __read(arguments), false));
            var method = bindMethod(add);
            this.refs.summaryClose[method]('click', this.toggleSummary);
            this.refs.summaryOverlay[method]('click', this.toggleSummary);
            if (this.refs.clearCoupon)
                this.refs.clearCoupon[method]('click', this.clearCoupon);
            store.checkoutSummaryOpened.toggleListener(add, this.toggleSummary);
        }
    });
    Object.defineProperty(CheckoutSummary.prototype, "initialized", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            _super.prototype.initialized.call(this);
        }
    });
    Object.defineProperty(CheckoutSummary.prototype, "getModulesMap", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __assign(__assign({}, _super.prototype.getModulesMap.call(this)), { products: ['.row-product-card', RowProductCard], checkoutFooter: ['.checkout-footer', CheckoutFooter], couponForm: ['.checkout-promo__form', Form] });
        }
    });
    /* SHOW */
    Object.defineProperty(CheckoutSummary.prototype, "show", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (previousPage, previousEl) {
            return __awaiter(this, void 0, void 0, function () {
                var isIdentical;
                var _this = this;
                var _a;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            this.resize();
                            this.invoke('show');
                            isIdentical = (previousEl === null || previousEl === void 0 ? void 0 : previousEl.innerHTML) === ((_a = this.el) === null || _a === void 0 ? void 0 : _a.innerHTML);
                            if (isIdentical)
                                anime.set(this.el, { opacity: 1 });
                            // const isSamePage = previousPage === this.pageName() && previousPage !== PanelPage.pageName
                            return [4 /*yield*/, anime({
                                    targets: this.el,
                                    opacity: [0, 1],
                                    duration: frameToMs(15),
                                    easing: 'linear',
                                    delay: frameToMs(15),
                                    changeBegin: function () {
                                    },
                                    complete: function () {
                                        defer(function () { return _this.resize(); });
                                    }
                                }).finished];
                        case 1:
                            // const isSamePage = previousPage === this.pageName() && previousPage !== PanelPage.pageName
                            _b.sent();
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    Object.defineProperty(CheckoutSummary.prototype, "hide", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (nextPage, previousEl) {
            return __awaiter(this, void 0, void 0, function () {
                var isIdentical;
                var _a;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            isIdentical = (previousEl === null || previousEl === void 0 ? void 0 : previousEl.innerHTML) === ((_a = this.el) === null || _a === void 0 ? void 0 : _a.innerHTML);
                            if (isIdentical)
                                return [2 /*return*/];
                            return [4 /*yield*/, anime({
                                    targets: this.el,
                                    opacity: [1, 0],
                                    duration: frameToMs(15 / 2),
                                    easing: 'linear',
                                    delay: frameToMs(15)
                                }).finished];
                        case 1:
                            _b.sent();
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    Object.defineProperty(CheckoutSummary.prototype, "setProductsWrapperHeight", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var headerHeight = this.refs.summaryHeader.offsetHeight;
            var footerHeight = this.refs.summaryPromoTotals.offsetHeight;
            var productsHeight = this.refs.summaryProducts[0].offsetHeight;
            var substractHeight = headerHeight + footerHeight + 20;
            var totalHeight = headerHeight + footerHeight + productsHeight;
            var height = "calc(var(--ivh) - ".concat(substractHeight, "px)");
            if (totalHeight < window.innerHeight)
                return;
            this.refs.summaryProducts[0].style.height = height;
        }
    });
    Object.defineProperty(CheckoutSummary.prototype, "resize", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            _super.prototype.resize.call(this);
            this.setProductsWrapperHeight();
            if (!mqStore.tabletPortrait.get()) {
                this.refs.summaryWrapper.style.display = 'block';
                this.refs.summaryWrapper.style.transform = 'translateY(0)';
            }
            else if (mqStore.tabletPortrait.get() && this.el.parentElement && !this.el.parentElement.classList.contains('opened')) {
                this.refs.summaryWrapper.style.display = 'none';
                this.refs.summaryWrapper.style.transform = 'translateY(var(--vh))';
            }
        }
    });
    return CheckoutSummary;
}(PanelPage));
export default CheckoutSummary;
