var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import Drawer from 'components/drawer/Drawer';
import NativeSelect from 'components/native-select/NativeSelect';
import { bindEmitterMethod } from 'helpers/bind';
import store from 'store';
var WebstoreSwitcher = /** @class */ (function (_super) {
    __extends(WebstoreSwitcher, _super);
    function WebstoreSwitcher(el, options) {
        var _this = _super.call(this, el, options) || this;
        Object.defineProperty(_this, "allLanguages", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: []
        });
        Object.defineProperty(_this, "onAfterRouteChange", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function () {
                _this.refs.form.action = window.location.pathname;
            }
        });
        Object.defineProperty(_this, "onSelectChange", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (option) {
                var languages = option.dataset.languages.split(',');
                var availableLanguages = _this.allLanguages.filter(function (language) { return languages.includes(language.value); });
                _this.allLanguages.forEach(function (language) { language.hidden = !languages.includes(language.value); });
                availableLanguages[0].selected = true;
            }
        });
        Object.defineProperty(_this, "onStayClick", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (e) {
                if (_this.el.dataset.splash === 'false')
                    e.preventDefault();
                store.drawer.set(null);
                store.persistantOverlay.set(false);
            }
        });
        _this.name = 'webstore-switcher';
        _this.params = {
            desktop: {
                axis: 'X',
                direction: 'reversed'
            },
            mobile: {
                axis: 'Y',
                direction: 'reversed'
            }
        };
        return _this;
    }
    Object.defineProperty(WebstoreSwitcher.prototype, "initialized", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            _super.prototype.initialized.call(this);
            if (this.el.dataset.splash === 'true')
                store.drawer.set('webstore-switcher');
            this.allLanguages = Array.from(this.modules.nativeSelect[1].el.children[0].children);
        }
    });
    Object.defineProperty(WebstoreSwitcher.prototype, "getModulesMap", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return {
                nativeSelect: ['.native-select', NativeSelect]
            };
        }
    });
    Object.defineProperty(WebstoreSwitcher.prototype, "bindEvents", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (add) {
            _super.prototype.bindEvents.call(this, add);
            var emitterMethod = bindEmitterMethod(add);
            this.refs.stay.addEventListener('click', this.onStayClick);
            this.modules.nativeSelect[0][emitterMethod]('change', this.onSelectChange);
            var p = this.parent;
            p.pageManager[emitterMethod]('load:end', this.onAfterRouteChange);
        }
    });
    return WebstoreSwitcher;
}(Drawer));
export default WebstoreSwitcher;
